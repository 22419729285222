.cv-scroll-container::-webkit-scrollbar {
  display: none; /* Hide scrollbar */
}

.wooden-background {
  background-image: url("/public/wooden.png");
  padding-block: 50px;
}

.primary-button {
  background: radial-gradient(
    circle at 3% 7.4%,
    rgb(0, 144, 243) 0%,
    rgb(0, 86, 240) 90%
  );
  padding-inline: 15px;
  padding-block: 6px;
  color: white;
  font-weight: 450;
  border-radius: 8px;
  border: 1.5px solid rgb(0, 86, 240);
  cursor: pointer;
  transition: background 0.3s ease; /* Add transition property */
}

.primary-button:disabled {
  background: radial-gradient(
    circle at 3% 7.4%,
    rgb(112, 183, 254) 0%,
    rgb(77, 119, 191) 90%
  );
  cursor: not-allowed;
  border: 1.5px solid rgb(150, 150, 150);
  color: white;
}

.primary-button:not(:disabled):hover {
  background: radial-gradient(
    circle at 3% 7.4%,
    rgb(4, 134, 220) 0%,
    rgb(1, 62, 177) 90%
  );
}

.primary-button:active {
  background: radial-gradient(
    circle at 3% 7.4%,
    rgb(0, 86, 240) 0%,
    rgb(0, 144, 243) 90%
  );
}

.secondary-button {
  padding-inline: 15px;
  padding-block: 6px;
  color: rgb(0, 86, 240);
  background-color: white;
  font-weight: 450;
  border-radius: 8px;
  border: 1.5px solid rgb(0, 86, 240);
  cursor: pointer;
}

.secondary-button:hover {
  background: radial-gradient(
    circle at 3% 7.4%,
    rgb(0, 144, 243) 0%,
    rgb(0, 86, 240) 90%
  );
  color: white;
  transition: all 200ms ease-in-out;
}

.secondary-button:active {
  background: radial-gradient(
    circle at 3% 7.4%,
    rgb(0, 86, 240) 0%,
    rgb(0, 144, 243) 90%
  );
  transform: scale(0.98); /* Slightly shrink the button */
}

.white-button {
  text-align: center;
  padding-inline: 20px;
  padding-block: 6px;
  background-color: white;
  color: black;
  font-weight: 600;
  font-size: small;
  border-radius: 16px;
}

.custom-shadow {
  box-shadow: 0px 0px 20px rgb(215, 210, 210);
}

.primary-gradient {
  background: radial-gradient(
    circle at 3% 7.4%,
    rgb(0, 86, 240) 0%,
    rgb(0, 144, 243) 90%
  );
  color: white;
}

.vertical-gradient {
  background: linear-gradient(
    to bottom,
    rgb(0, 144, 243) 0%,
    rgb(0, 44, 240) 90%
  );
  color: white;
}

.error {
  text-align: center;
  color: red;
  font-weight: bold;
}

.otp-input {
  border: 1px solid gray;
  min-width: 30px;
  background-color: black;
  color: white;
}

.question {
  margin-bottom: 15px;
}

.scores {
  display: flex;
  justify-content: space-around;
  margin-bottom: 15px;
}

.score {
  text-align: center;
  border: 1px solid #eee;
  padding: 10px;
  border-radius: 5px;
  flex: 1;
  margin: 0 5px;
}

.score .label {
  font-weight: bold;
  display: block;
}

.score .value {
  font-size: 1.2em;
}

.improvement-tips {
  margin-bottom: 10px;
}

.recording-gif {
  width: 90px;
  height: 90px;
}

.svg-image {
  width: 100%;
  height: auto;
}

.selection-container {
  margin-top: 50px;
  padding-right: 40px;
  position: relative;
  border-left: 2px solid green;
  margin-left: 40px;
}

.dot-circle {
  border: 2px solid green;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  /* position: absolute;
    top: 0px;
    left: 0px; */
}

.relative {
  position: relative;
  display: flex;
  align-items: center;
}

.bar {
  height: 2px;
  background-color: green;
  width: 30px;
}

.step-container {
  position: relative;
  margin-left: 30px;
  min-height: 25vh;
  padding: 20px;
  border-radius: 20px;
  background-color: rgba(128, 128, 128, 0.125);
  margin-block: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.filled {
  background-color: green;
}

.step {
  position: absolute;
  /* background-color: aqua; */
  display: flex;
  align-items: center;
  top: calc(50%-5px);
  left: -48px;
  min-height: 15px;
  min-width: 15px;
}

.secondary-text {
  color: rgb(175, 163, 163);
}

.current {
  background-color: rgba(0, 128, 0, 0.278);
}

.start-text {
  position: absolute;
  top: -25px;
  left: -15px;
  color: green;
}

.error {
  color: red;
  font-size: large;
  font-weight: 500;
}

strong {
  display: block;
}

.app-bar {
  color: #f4bf43;
}

.flex-it {
  display: flex;
}

.markdown-container {
  margin-top: 10px;
}

.profile-image {
  height: 35px;
  width: 35px;
  border-radius: 10px;
}

.container {
  margin: 20px;
}

.result-heading {
  display: flex;
  padding: 10px;
}

/* h1 {
    color: #333;
} */

#inputs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin-bottom: 20px;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 400px;
}

#inputs label {
  color: #555;
  font-weight: bold;
  display: flex;
  align-items: center;
}

#inputs input {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

#heatmap-container {
  display: flex;
  align-items: center;
  scale: 0.9;
}

#heatmap {
  margin-right: 0;
}

.cell {
  stroke: white;
  stroke-width: 2px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
  transition: stroke-width 0.3s ease, transform 0.2s ease,
    fill-opacity 0.2s ease;
}

.cell:hover {
  stroke-width: 4px;
  transform: scale(1);
  fill-opacity: 0.8;
}

.axis-label {
  font-weight: bold;
  text-anchor: middle;
}

#legend {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.legend-color {
  width: 20px;
  height: 20px;
  border-radius: 3px;
  margin-right: 5px;
}

.tooltip {
  position: absolute;
  text-align: center;
  text-wrap: nowrap;
  width: 80px;
  height: 25px;
  padding: 5px;
  font: 14px sans-serif;
  background: lightsteelblue;
  color: white;
  border: 0px;
  border-radius: 8px;
  pointer-events: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
}

.tooltip::after {
  content: "";
  position: absolute;
  bottom: -10px;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: lightsteelblue transparent transparent transparent;
}

.legend-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid rgb(0, 0, 0);
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease, top 0.3s ease;
}

.button-appu img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.secondary-text {
  color: rgb(157, 146, 146);
}

.button-appu {
  transform: translateY(9px);
  margin-left: 10px;
}

.ask-appu-btn {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.container {
  scale: 0.8;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  padding-bottom: 0px;
  background-color: #fff;
  border-radius: 10px;
}

#form-section {
  width: 100%;
  margin-bottom: 20px;
}

.input-group {
  margin-bottom: 20px;
}

.input-group h3 {
  margin-bottom: 10px;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
}

.input-group input {
  width: calc(100% - 22px);
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

#chart-section {
  width: 100%;
  display: none;
}

.tooltip {
  position: absolute;
  text-align: center;
  width: auto;
  height: auto;
  padding: 5px;
  font: 12px sans-serif;
  background: lightsteelblue;
  border: 0px;
  border-radius: 8px;
  pointer-events: none;
  opacity: 0;
}

.axisLabel {
  font-size: 10px;
  fill: #737373;
}

.legend {
  font-size: 14px;
}

.legend rect {
  fill: none;
  stroke-width: 2px;
}

.gridCircle:hover {
  fill: #cdcdcd;
  fill-opacity: 0.3;
  stroke: #cdcdcd;
  stroke-opacity: 0.7;
}

@keyframes slideUp {
  from {
    transform: translateY(0);
    opacity: 1;
  }

  to {
    transform: translateY(-100%);
    opacity: 0;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.slide-up {
  animation: slideUp 0.5s forwards;
}

.slide-in {
  animation: slideIn 0.5s forwards;
}

.active-bar {
  border-bottom: blue solid 1px;
}

.glassy-bg {
  /* From https://css.glass */
  background: rgba(255, 255, 255, 1); /* Increase opacity to 0.8 */
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.primary-shadow {
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}

.custom-font {
  font-family: "Helvatica", "sans-serif";
}

.image-transition {
  max-height: 350px;
  margin-inline: auto;
}

.button-navigation {
  display: flex;
  align-items: center;
  gap: 5px;
}

.arrow-button {
  background-color: #cce7ee;
  color: #2a6f82;
  clip-path: polygon(
    0 0,
    /* Top-left */ calc(100% - 15px) 0,
    /* Top-right inward */ 100% 50%,
    calc(100% - 15px) 100%,
    0 100% /* Bottom-left */
  );
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s;
}

.arrow-button.active {
  background-color: #53b7d1; /* Active button color */
}

.arrow-button:hover {
  background-color: #b3d8e4; /* Hover color */
}

.pricing-gradient {
  background: linear-gradient(to right, #1995ad, #ffffff00);
}
