p a{
  color: rgb(0, 153, 255);
  
}
p a:hover{
  text-decoration: underline;
}

strong {
  display: inline; 
}
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f0f0f5;
}

.container {
  max-width: 900px;
  margin: 20px auto;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #0073e6;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

header .personal-info {
  flex: 1;
}

header h1 {
  font-size: 30px;
  color: #333;
  margin-bottom: 5px;
}

header .personal-info p {
  margin: 0;
  font-size: 14px;
  color: #555;
}

header img {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  object-fit: cover;
}

section {
  margin-bottom: 30px;
}

section h2 {
  font-size: 24px;
  color: #0073e6;
  border-bottom: 2px solid #0073e6;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

section p,
section ul {
  font-size: 14px;
  color: #555;
  line-height: 1.6;
  margin-bottom: 10px;
}

section ul {
  padding-left: 20px;
  list-style-type: disc;
}

.qualification,
.experience,
.projects {
  display: flex;
  flex-direction: column;
}

.qualification-item,
.experience-item,
.project-item {
  margin-bottom: 20px;
}

.skills ul {
  display: flex;
  flex-wrap: wrap;
}

.skills ul li {
  background-color: #0073e6;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  margin: 5px;
  font-size: 13px;
}

.optional {
  color: #333;
}

footer {
  text-align: center;
  padding-top: 20px;
  border-top: 1px solid #ccc;
  font-size: 14px;
  color: #777;
}
